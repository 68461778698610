// ==========================================================================
// LAYOUT / SITE
// ==========================================================================

// Grid mixin reference: http://sassflexboxgrid.com/views/mixins.html

//
// Globals
// --------------------------------------------------------------------------

@-webkit-viewport {
	width: device-width;
}

@-moz-viewport {
	width: device-width;
}

@-ms-viewport {
	width: device-width;
}

@-o-viewport {
	width: device-width;
}

@viewport {
	width: device-width;
}

html {
	background: #f5f5f5;
	-webkit-overflow-scrolling: touch;
	-webkit-tap-highlight-color: lighten($base-font-color, 75%);
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

body {
	background-color: transparent;
	font-family: $base-font-family;
	line-height: $base-line-height;
	color: $base-font-color;
	-webkit-font-smoothing: antialiased;
}

::-moz-selection {
	background-color: lighten($action-color, 10%);
	color: #fff;
	text-shadow: none;
}

::selection {
	background-color: lighten($action-color, 10%);
	color: #fff;
	text-shadow: none;
}

/* ---------------------------- Content Wrappers ---------------------------- */
.wrapper,
.wrapper--full {
	padding-left: 2.5%;
	padding-right: 2.5%;
	width: auto;
	position: relative;

	@include respond-above("phone") {
		padding-left: 2%;
		padding-right: 2%;
	}
}

.wrapper {
	@include respond-above("tablet-landscape") {
		padding-left: 0;
		padding-right: 0;
		width: 960px;
		margin-left: auto;
		margin-right: auto;
	}
	@include respond-above("desktop") {
		padding-left: 0;
		padding-right: 0;
		width: 1140px;
		margin-left: auto;
		margin-right: auto;
	}

	@include respond-above("big-desktop") {
		width: 1400px;
	}
}

.page-container {
	background: #f3f3f3;
}

/* -------------------------------------------------------------------------- */
/*                               SITE SPECIFICS                               */
/* -------------------------------------------------------------------------- */
// #masthead {
// 	@include row();
// 	@include center();
// 	padding-top: $base-spacing;

// 	header {
// 		@include col(12);
// 		@include col(sm, 10);
// 		@include respond-above("tablet-landscape") {
// 			padding-top: $base-spacing * 2;
// 		}
// 	}

// 	aside {
// 		display: none;
// 		@include respond-above("tablet-portrait") {
// 			display: block;
// 		}
// 		@include col(2);
// 	}
// }

// #main {
// 	@include row();
// }

// #form-col {
// 	@include col(12);
// 	@include col(sm, 5);
// 	@include col(md, 4);

// 	h3 {
// 		font-family: $base-font-family;
// 		font-size: $base-font-size;
// 		text-transform: uppercase;
// 		letter-spacing: 0.05em;
// 	}
// }
// #preview-col {
// 	@include col(12);
// 	@include col(sm, 6);
// 	@include col(md, 7);
// 	@include col-offset(sm, 1);

// 	position: relative;
// }

// #preview-outer {
// 	position: -webkit-sticky;
// 	position: sticky;
// 	top: $base-spacing;

// 	header {
// 		display: flex;

// 		em {
// 			font-style: italic;
// 		}
// 	}

// 	h2 {
// 		font-family: $base-font-family;
// 		font-weight: 400;
// 		font-size: 1.25em;
// 		letter-spacing: 0;
// 		margin-right: 1em;
// 	}

// 	footer {
// 		padding: $base-spacing 0;
// 		text-align: center;

// 		.btn {
// 			margin-bottom: $small-spacing;
// 		}
// 	}
// }

// #preview-container {
// 	position: relative;
// 	background: #fff;
// 	padding: 20px;
// 	padding-top: 60px;
// 	border-radius: 10px;
// 	border: 1px solid $light-gray;
// 	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

// 	&:before,
// 	&:after {
// 		display: block;
// 		background: rgba($light-gray, 0.2);
// 		height: 15px;
// 		content: "";
// 		position: absolute;
// 		top: 0;
// 		width: 80%;
// 	}
// 	&:after {
// 		top: 26px;
// 		width: 50%;
// 	}
// }

// #preview {
// 	overflow: hidden;
// }

.banner-wrapper {
	background: #171d35 url("../img/banner-liberty-sm.jpg") no-repeat bottom
		center;
	background-size: contain;

	@include respond-above("tablet-portrait") {
		background-size: cover;
	}

	@include respond-above("tablet-landscape") {
		background-image: url("../img/banner-liberty.jpg");
		background-position: bottom left;
		background-size: contain;
	}
}

/* -------------------------------- MASTHEAD -------------------------------- */
#masthead {
	padding: 1rem 0;
	color: white;

	@include respond-above("tablet-portrait") {
		padding: 2rem 0 0;
	}
}
.masthead--mobile {
	svg {
		display: block;
		width: 40%;
		// max-width: 20rem;
		margin: 0 auto;
	}
	@include respond-above("tablet-portrait") {
		display: none;
	}
}
.masthead--desktop {
	display: none;

	@include respond-above("tablet-portrait") {
		// display: block;
		@include row();
		// @include g.grid($justify-content: space-between);
	}

	.logo-primary {
		@include col(5);
		@include col(sm, 4);

		// @include respond-above("tablet-landscape") {
		// 	@include col(4);
		// }
		// @include respond-above("desktop") {
		// 	@include col(3);
		// }
	}
	.logo-secondary {
		@include col(7);
		@include col(sm, 6);
		@include col(md, 5);
		@include col-offset(sm, 2);
		@include col-offset(md, 3);
		svg {
			width: 40%;
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
		// @include respond-above("tablet-landscape") {
		// 	@include col(6);
		// }
	}
}

//FFG Brand Colors
.logo-ffg {
	.fill-star {
		fill: $ffg-aqua;
	}
	.fill-flag {
		fill: $ffg-red;
	}
	.fill-text {
		fill: $ffg-blue;
	}
	.fill-connect {
		fill: $ffg-aqua;
	}

	&.logo--reverse {
		.fill-text {
			fill: white;
		}
	}
}

// EDR Brand Colors
.logo-edr,
.edr-tag {
	.fill-mark {
		fill: $edr-orange;
	}
	.fill-text {
		fill: $edr-tan;
	}
	&.logo--reverse {
		.fill-text {
			fill: $edr-tan;
		}
	}
}

/* --------------------------------- BANNER --------------------------------- */
.banner {
	display: grid;
	color: white;
	// gap: 1rem;
	column-gap: 1.5rem;
	align-items: start;
	grid-template-areas:
		"heading"
		"form"
		"content";
	padding-top: $small-spacing;
	padding-bottom: $small-spacing;

	.banner-header {
		grid-area: heading;
		color: var(--clr-neutral-100);
		text-align: center;
		margin-bottom: $small-spacing;
	}
	.banner-content {
		grid-area: content;
		color: var(--clr-neutral-300);
	}
	.form-wrapper {
		grid-area: form;
	}

	@include respond-above("tablet-portrait") {
		grid-template-areas:
			"heading form"
			"content form";

		grid-template-columns: 1fr 1fr;

		.banner-header {
			padding-top: 1rem;
			text-align: left;
		}
	}

	@include respond-above("tablet-landscape") {
		grid-template-columns: 7fr 5fr;
		column-gap: 4.5rem;
		.banner-header {
			padding-top: 3rem;
		}
	}
}

/* -------------------------------------------------------------------------- */
/*                                    FORM                                    */
/* -------------------------------------------------------------------------- */
.form-wrapper {
	form {
		background: #fff;
		border-radius: 4px;
		color: var(--clr-neutral-700);
	}

	header,
	fieldset,
	footer {
		padding: 0.75rem 1rem;
	}

	header {
		text-align: center;
		border-bottom: 1px #eee solid;
		padding-top: 1.5rem;
	}
	section {
	}

	footer {
		padding: 1.5rem 2.5rem 0.75rem;

		.security {
			margin: 5px auto 0;
			width: 30px;
			display: block;
		}
	}

	.btn-group {
		position: relative;
		text-align: center;

		.btn {
			position: relative;
			z-index: 2;
			width: 80%;
		}

		&:before {
			border-top: 1px solid #eee;
			content: "";
			margin: 0 auto; /* this centers the line to the full width specified */
			position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
			top: 50%;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			z-index: 0;
		}
	}
	/* Typography */
}

/* -------------------------------------------------------------------------- */
/*                                PAGE CONTENT                                */
/* -------------------------------------------------------------------------- */

#feature-content {
	padding-top: $base-spacing * 2;
	padding-bottom: $base-spacing * 3;

	.features {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 1rem;
		row-gap: 1rem;
		padding-left: 1rem;
		padding-right: 1rem;
		max-width: 1100px;
		margin: 0 auto;
	}
	.feature {
		flex: 1;
		display: flex;
		flex-direction: row;
		column-gap: 1rem;

		div {
			flex: 6;
		}
		.icon {
			flex: 1;
			width: 20%;
		}
	}

	@include respond-above("tablet-portrait") {
		.features {
			flex-direction: row;
			gap: 0;
			column-gap: 1rem;
		}
		.feature {
			flex-direction: column;
			row-gap: 0;
			div {
				flex: auto;
			}
		}
		.icon {
			margin: 0 auto;
		}
	}
	@include respond-above("desktop") {
		.features {
			column-gap: 3rem;
		}
	}
}

/* -------------------------------------------------------------------------- */
/*                                 SITE FOOTER                                */
/* -------------------------------------------------------------------------- */
#site-footer {
	background: shade($ffg-red, 60);
	padding-top: $small-spacing;
	padding-bottom: $base-spacing * 1.5;

	.logo {
		margin: $base-spacing auto;
		width: 40%;
		max-width: 400px;

		svg * {
			fill: tint($ffg-red, 20);
		}
	}

	.footer-content {
		max-width: 800px;
		margin: $base-spacing auto;
	}
	.legal {
		margin-bottom: $base-spacing *2;
	}
}
/* RIPPED STYLE FROM RESOURCES */

.resources-page {
.wrapper {
    padding-left: 5%;
    padding-right: 5%;
    width: auto;
    position: relative;
    max-width: 68em;
    margin-left: auto;
    margin-right: auto
}

.wrapper:after {
    clear: both;
    content: "";
    display: table
}



.masthead {
    background: #1E2748;
    padding: .5em 0
}

.masthead .logos-wide {
    display: none
}

.masthead .logos-narrow {
    display: block
}

.masthead .logos-narrow img {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 48.821174201%;
    margin-left: 25.5894128995%
}

.masthead .logos-narrow img:last-child {
    margin-right: 0
}

.masthead .logo {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 65.8807828007%;
    margin-left: 17.0596085997%;
    height: 23px
}

.masthead .logo:last-child {
    margin-right: 0
}

@media screen and (min-width: 480px) {
    .masthead .logo {
        float:left;
        display: block;
        margin-right: 2.3576515979%;
        width: 48.821174201%;
        margin-left: 25.5894128995%
    }

    .masthead .logo:last-child {
        margin-right: 0
    }
}

@media screen and (min-width: 767px) {

    .masthead {
        padding-top:1em
    }

	.masthead .logos-narrow {
        display:none
    }

	.masthead .logos-wide {
        display:block
    }

	.masthead .logo {
        float:left;
        display: block;
        margin-right: 2.3576515979%;
        width: 31.7615656014%;
        margin-left: 0;
        height: 30px
    }

    .masthead .logo:last-child {
        margin-right: 0
    }


    .masthead .co-brand {
        float:left;
        display: block;
        margin-right: 2.3576515979%;
        width: 18.9668591516%;
        margin-left: 25.5894128995%;
        height: 30px
    }

    .masthead .co-brand:last-child {
        margin-right: 0
    }

    .wrapper {
        padding-left:2%;
        padding-right: 2%
    }
	
}

@media screen and (min-width: 992px) {

    .masthead .logo {
        float:left;
        display: block;
        margin-right: 2.3576515979%;
        width: 23.2317613015%
    }

    .masthead .logo:last-child {
        margin-right: 0
    }

    .masthead .co-brand {
        margin-left:42.6490214991%
    }

    .wrapper {
        padding-left:0;
        padding-right: 0;
        width: 720px;
        margin-left: auto;
        margin-right: auto
    }
}

@media screen and (min-width: 1300px) {
    .wrapper {
        width:1170px
    }
}

#primary-footer {
    text-align: center;
    padding: 1.5em 1em
}

#primary-footer p {
    font-size: .875rem
}
#resource-frame {
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    display: block;
    height: 0;
    overflow: hidden
}
}