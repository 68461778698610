// Custom Font Stacks
$sans-serif: "Lato", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans",
	"DejaVu Sans", "Bitstream Vera Sans", "Liberation Sans", Verdana,
	"Verdana Ref", sans-serif;
$serif: "Domine", Constantia, "Lucida Bright", Lucidabright, "Lucida Serif",
	Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia,
	serif;
//$display:   "Montserrat", "Lato", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", "Bitstream Vera Sans", "Liberation Sans", Verdana, "Verdana Ref", sans-serif;

// General Font Fmaily Definitions
$base-font-family: $sans-serif;
$alt-font-family: $serif;
//$display-font-family:   $display;

$heading-font-family: $alt-font-family;

// Font Sizes
$base-font-size: 1em;
// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 4px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;

/* --------------------------------- COLORS --------------------------------- */

/* Brand Specifics */
$ffg-red: #da2550;
$ffg-aqua: #27a3c9;
$ffg-blue: #143b46;
$edr-orange: #f76511;
$edr-tan: #f1f4ef;

// General Colors
$dark-gray: #555;
$medium-gray: #999;
$light-gray: #ddd;

/* Basics */
$colors: (
	neutral: (
		100: hsl(0, 0%, 100%),
		200: hsl(0, 0%, 87%),
		300: hsl(0, 0%, 80%),
		700: hsl(0, 0%, 40%),
		800: hsl(0, 0%, 30%),
		900: hsl(0, 0%, 15%),
	),
	action: (
		400: hsl(104, 50%, 60%),
		500: hsl(104, 50%, 50%),
		600: hsl(104, 50%, 40%),
	),
);

$brand-blue: $ffg-aqua;
$brand-red: $ffg-red;

// Font Colors
$base-font-color: desaturate(darken($medium-gray, 20%), 10%);
$action-color: $ffg-aqua;
$action-color-alt: $ffg-blue;

// Links
$link-color: $action-color;
$link-colorvisited: $link-color;
$link-colorhover: lighten($link-color, 20%);
$link-colorfocus: $action-color;

// Border
$base-border-color: darken($light-gray, 10);
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: #f2f2f2;
$secondary-background-color: tint($base-border-color, 75%);

// Forms
$form-box-shadow: inset 0 2px 3px 0 rgba(black, 0.1),
	inset 0 1px 1px 0 rgba(black, 0.2);
//$form-box-shadow-focus: 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3), inset 0 2px 3px 0 rgba(0,0,0,0.3);
$form-box-shadow-focus: 0 0 1px 3px rgba($action-color, 0.1),
	inset 0 2px 3px 0 rgba(0, 0, 0, 0.3);

// Animations
$base-duration: 250ms;
$base-timing: ease;
$stagger-time: 0.25ms;

/* --------------------------- GRID CUSTOMIZATIONS -------------------------- */
$gutter: 0; // Set gutter size
