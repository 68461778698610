#{$all-buttons},
.btn {
	appearance: none;
	background-color: var(--clr-action-500);
	border: 0;
	border-radius: 100em;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-family: $base-font-family;
	font-size: $base-font-size;
	-webkit-font-smoothing: antialiased;
	font-weight: 600;
	line-height: 1;
	padding: ($small-spacing * 1.25) ($base-spacing * 2);
	text-align: center;
	text-decoration: none;
	transition: all $base-duration $base-timing;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;
	box-shadow: 0 2px 4px 0 rgba(black, 0.1);

	&:hover,
	&:focus {
		background-color: var(--clr-action-400);
		color: #fff;
	}

	&:disabled {
		cursor: not-allowed;
		opacity: 0.5;

		&:hover {
			background-color: var(--clr-action-500);
		}
	}
}

.btn {
	font-size: $base-font-size * 1.25;
	padding: ($base-spacing * 0.5) ($base-spacing * 1);
	border-radius: 100em;
	@include transition(all $base-duration $base-timing);

	&:hover {
		background-color: $ffg-red;
		color: #fff;
	}
	&:focus {
		background-color: shade($ffg-red, 20);
	}
	// @include grid-media($grid-sm){
	//   font-size: $base-font-size * 1.15;
	// }

	// @include grid-media($grid-md){
	//   font-size: $base-font-size * 1.25;
	// }
}

// Button Alternate Sizes
.btn--micro {
	padding: ($small-spacing * 0.75) ($base-spacing * 0.9);
	font-size: $base-font-size * 0.9;
	letter-spacing: 0.1em;
	font-family: $base-font-family;
}

.btn--puffy {
	font-size: $base-font-size * 1.8;
	box-shadow: 0 3px 6px rgba(black, 0.2), 0 1px 2px rgba(black, 0.2);
	padding: ($base-spacing * 0.35) ($base-spacing * 1.25);

	// @include grid-media($grid-sm){
	//   width: auto;
	//   font-size: $base-font-size * 2.25;
	//   padding: ($base-spacing * .25) ($base-spacing * 1.25);
	// }
	//  @include grid-media($grid-md){
	//   width: auto;
	//   font-size: $base-font-size * 2.5;
	//   padding: ($base-spacing * .35) ($base-spacing * 1.25);
	// }
}

// Button Alternate Styles
.btn--primary {
	background-color: $action-color;

	&:hover {
		background-color: shade($action-color, 20%);
	}
}

.btn--secondary {
	background-color: $action-color-alt;

	&:hover {
		background-color: shade($action-color-alt, 10%);
	}
}

.btn--alternate {
	background-color: transparent;
	border: 2px solid $action-color;
	color: $action-color;
	box-shadow: none;

	&:hover {
		background-color: $action-color;
		// color: $lhpp-blue;
	}
}

.btn--alternate-secondary {
	background-color: shade($action-color, 10%);

	&:hover {
		background-color: shade($action-color, 10%);
		//  box-shadow: inset 0 0 0 5px shade( $diablo-gray, 15% );
	}
}

.prev {
	margin-right: 1em;
}

/* Button with Icon */
.btn--icon {
	padding-left: $small-spacing;
	.icon {
		width: 0.9em;
		height: 0.9em;
		fill: currentColor;
		opacity: 0.5;
		margin-right: 0.25em;
	}
}
