// ==========================================================================
// LAYOUT / FORMS
// ==========================================================================
fieldset {
	background-color: transparent;
	border: 0;
	margin: 0;
	padding: 0;
}

legend {
	font-weight: 600;
	margin-bottom: $small-spacing / 2;
	padding: 0;
}

label {
	display: inline-block;
	font-weight: 400;
	margin-bottom: $small-spacing / 2;
	font-size: $base-font-size;
	line-height: 1.2em;
	color: var(--clr-neutral-700);

	small {
		font-size: $base-font-size * 0.8;
		font-weight: 400;
		background: rgba(0, 0, 0, 0.05);
		padding: 0.1em 0.3em;
		margin-left: 0.5em;
		border-radius: 2px;
	}
}

input,
select,
textarea {
	display: block;
	font-family: $base-font-family;
	font-size: $base-font-size;
}

#{$all-text-inputs} {
	appearance: none;
	background-color: $base-background-color;
	border: $base-border;
	border-radius: $base-border-radius;
	box-shadow: $form-box-shadow;
	box-sizing: border-box;
	margin-bottom: 0;
	padding: ($base-spacing / 2) ($base-spacing / 2);
	transition: border-color $base-duration $base-timing;
	width: 100%;

	&:hover {
		border-color: shade($base-border-color, 20%);
	}

	&:focus {
		border-color: $brand-blue !important;
		box-shadow: $form-box-shadow-focus;
		outline: none;
	}

	&:disabled {
		background-color: lighten($light-gray, 8) !important;
		cursor: not-allowed;
		box-shadow: none;
		border: 1px solid lighten($light-gray, 2) !important;
	}

	&::placeholder {
		color: tint($base-font-color, 20%);
	}

	// @include grid-media($grid-md) {
	// 	padding: ($base-spacing / 1.5) ($base-spacing / 2) ($base-spacing / 1.75);
	// }
}

textarea {
	resize: vertical;
}

[type="checkbox"],
[type="radio"] {
	display: inline;
	margin-right: $small-spacing / 2;
	width: 20px !important;
}

[type="file"] {
	margin-bottom: $small-spacing;
	width: 100%;
}

select {
	margin-bottom: $small-spacing;
	width: 100%;
}

input:not([type="submit"]):not([type="file"]),
textarea {
	width: 100%;
	border: $base-border;
	//background: #fff;

	&::placeholder {
		color: darken($medium-gray, 10%);
	}
}

.field-md {
	max-width: 14em;
}
.field-sm {
	max-width: 8em;
}
.field-xs {
	max-width: 4em;
}
//
// Special Select
// --------------------------------------------------------------------------
.select {
	display: block;
	font-size: $base-font-size;
	color: $dark-gray;
	line-height: 1.3;
	padding: ($base-spacing / 1.75) ($base-spacing / 2);
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	margin: 0;
	box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.03);
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
		linear-gradient(to bottom, #ffffff 0%, #f2f2f2 100%);
	background-repeat: no-repeat, repeat;
	background-position: right 0.7em top 50%, 0 0;
	background-size: 0.65em auto, 100%;
	border: $base-border;
	border-radius: 5px;

	&::-ms-expand {
		display: none;
	}
	&:hover {
		border-color: #888;
	}
	&:focus {
		border-color: #aaa;
		box-shadow: 0 0 1px 3px rgba($brand-blue, 0.4);
		box-shadow: 0 0 0 3px -moz-mac-focusring;
		color: #222;
		outline: none;
	}

	option {
		font-weight: normal;
	}

	// @include grid-media($grid-md) {
	// 	padding: ($base-spacing / 1.5) ($base-spacing / 2) ($base-spacing / 1.5);
	// }
}

//
// Special Checkbox
// --------------------------------------------------------------------------

.checkbox-label {
	display: block;
	position: relative;
	margin: auto;
	cursor: pointer;
	padding-left: 2.3rem;
	clear: both;
	font-weight: 400;
	font-size: 90%;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
	}

	.checkbox-custom {
		position: absolute;
		top: 0px;
		left: 0px;
		height: 24px;
		width: 24px;
		background-color: #fff;
		border-radius: 5px;
		transition: all 0.3s ease-out;
		@include transition(all 0.3s ease-out);
		background-color: #fff;
		border: $base-border;
		box-shadow: $form-box-shadow;

		&:after {
			position: absolute;
			content: "";
			left: 12px;
			top: 12px;
			height: 0px;
			width: 0px;
			border-radius: 5px;
			border: solid #ffffff;
			border-width: 0 3px 3px 0;
			-webkit-transform: rotate(0deg) scale(0);
			-ms-transform: rotate(0deg) scale(0);
			transform: rotate(0deg) scale(0);
			opacity: 1;
			transition: all 0.3s ease-out;
			@include transition(all 0.3s ease-out);
		}
	}

	input:checked ~ .checkbox-custom {
		background-color: $brand-blue;
		border-radius: 5px;
		-webkit-transform: rotate(0deg) scale(1);
		-ms-transform: rotate(0deg) scale(1);
		transform: rotate(0deg) scale(1);
		opacity: 1;
		border: 1px solid $brand-blue;

		&:after {
			-webkit-transform: rotate(45deg) scale(1);
			-ms-transform: rotate(45deg) scale(1);
			transform: rotate(45deg) scale(1);
			opacity: 1;
			left: 6px;
			top: 1px;
			width: 9px;
			height: 16px;
			border: solid #ffffff;
			border-width: 0 3px 3px 0;
			background-color: transparent;
			border-radius: 0;
		}
	}
}

//
// Parsley Validation
// --------------------------------------------------------------------------
input,
select,
textarea {
	&.parsley-success {
		color: #468847;
		background-color: #dff0d8;
		border: 1px solid #d6e9c6;
	}

	&.parsley-error {
		color: #b94a48;
		border-color: #b94a48 !important;
		box-shadow: $form-box-shadow, 0 0 10px rgba($action-color, 0.4),
			0 0 5px rgba($action-color, 0.4) inset;
	}
	&.parsley-required {
		color: #b94a48 !important;
	}
}

 .parsley-errors-list {
   margin: 2px 0 3px;
   padding: 5px;
   list-style-type: none;
   font-size: 0.8em;
   line-height: 0.9em;
   opacity: 0;
   background: tint( $ffg-red, 80%);
   color: shade( $ffg-red, 40% );

   transition: all .3s ease-in;
   -o-transition: all .3s ease-in;
   -moz-transition: all .3s ease-in;
   -webkit-transition: all .3s ease-in;

   li { margin: 0; }
 }

 .parsley-errors-list.filled {
   opacity: 1;
 }

//
// Basic Structure
// --------------------------------------------------------------------------

//
// Special Checkbox
// --------------------------------------------------------------------------

//
// Parsley Validation
// --------------------------------------------------------------------------
input,
select,
textarea {
	&.parsley-success {
		color: #468847;
		background-color: #dff0d8;
		border: 1px solid #d6e9c6;
	}

	&.parsley-error {
		color: #b94a48;
		border-color: #b94a48 !important;
		box-shadow: $form-box-shadow, 0 0 10px rgba($action-color, 0.4),
			0 0 5px rgba($action-color, 0.4) inset;
	}
	&.parsley-required {
		color: #b94a48 !important;
	}
}

//
// Basic Structure
// --------------------------------------------------------------------------

.form-group {
	@include clearfix();
	padding: 0.5em;
	position: relative;

	// Global Hovers
	&:hover {
		.form-label {
			color: #000;
		}
	}

	.btn {
		font-size: $base-font-size * 1.25;
	}

	@include respond-above("desktop") {
		padding: 0.5em 1em;
		margin-bottom: 1em;
	}

	@include respond-above("tablet-landscape") {
		.btn {
			font-size: $base-font-size * 1.5;
		}
	}

	// Labels
	.form-label {
		font-size: 0.8em;

		&--hidden {
			@include hide-visually();
		}

		@include respond-above("tablet-portrait") {
			font-size: 1em;
		}
	}

	.desc {
		font-weight: 700;
		font-size: $base-font-size * 0.7;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		color: #666;
		padding-left: 0.45em;
	}
	//
	// Specific Sections
	// --------------------------------------------------------------------------
	&--mo-margin {
		margin-bottom: $base-spacing * 1;
	}
	&--btn-row {
		text-align: center;

		.btn {
			width: 80%;
		}
		ul {
			@include respond-above("tablet-portrait") {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
			}
			@include respond-above("tablet-landscape") {
				flex-wrap: nowrap;
				flex-direction: row;
				justify-content: space-around;
			}
		}
		li {
			margin-bottom: 0.5em;
			font-size: 2em;

			&:last-child {
				margin-bottom: 0;
			}
		}
		a {
			padding-left: 0.75em;
			padding-right: 0.75em;
		}
	}

	// Checkboxes and Radios
	&--multi {
		div {
			display: block;
		}
		span {
			display: block;

			label {
				font-size: 1em;
				font-weight: 400;
			}
		}
	}

	// First/Last Name
	&--name {
		div {
			display: flex;
		}

		span {
			flex-grow: 1;
			margin-right: 1em;
			margin-bottom: 0;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	&--date-select {
		div {
			display: flex;
		}

		span {
			flex-grow: 1;
			margin-right: 1em;
			margin-bottom: 0;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	&--phone {
		div {
			display: flex;
		}

		span {
			//flex-grow: 1;
			padding-right: 2em;
			margin-bottom: 0;
			position: relative;

			&:after {
				content: "-";
				position: absolute;
				right: 0.35em;
				top: 0;
				font-size: 2em;
				color: #ccc;
			}

			&:last-child {
				margin-right: 0;
				&:after {
					content: none;
				}
			}
		}
	}

	&--address {
		div {
			display: flex;
		}

		span {
			flex-grow: 1;
			margin-right: 1em;
			margin-bottom: 0;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	&--zip {
		input {
			width: 200px !important;
		}
	}

	// Yes/No
	&--yesno {
		width: 100%;
		display: table;

		&:before {
			display: none;
		}

		> div {
			display: table-cell;
			vertical-align: top;
		}

		.yesno-label {
			padding-right: 1em;
			label {
				position: relative;
				width: 100%;
			}
		}

		.yesno-btns {
			width: 180px;
		}

		input {
			opacity: 0;
			position: absolute;
			z-index: -10;
			top: 0;
		}

		.btn {
			padding: 0.75em;
			display: block;
			text-align: center;
			border-radius: 5px;
			font-size: $base-font-size;
			letter-spacing: 0.05em;
			color: $medium-gray;
			line-height: 1em;
			text-transform: uppercase;
			box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.03);
			background-color: #fff;
			background-image: linear-gradient(
				to bottom,
				#ffffff 0%,
				#f2f2f2 100%
			);
			background-repeat: no-repeat, repeat;
			border: solid 1px rgba(0, 0, 0, 0.2);
			transition: all $base-duration $base-timing;

			&:hover {
				background-color: tint($brand-red, 30%);
				background-image: linear-gradient(
					to bottom,
					tint($brand-red, 30%) 0%,
					tint($brand-red, 20%) 100%
				);
				color: #fff;
			}

			&--selected {
				background: #999;
				color: #f2f2f2;
				box-shadow: 1px 1px 3px 0 inset rgba(0, 0, 0, 0.2),
					8px 8px 16px 0 inset rgba(0, 0, 0, 0.1),
					4px 4px 5px 0 rgba(255, 255, 255, 1);
				text-shadow: 0.5px 1px 3px rgba(0, 0, 0, 0.2);

				&:hover {
					background: #999;
					color: #f2f2f2;
				}
			}
		}
	}
}
