// ==========================================================================
// BASE
// ==========================================================================

html {
  box-sizing: border-box;
} 

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
}


hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}


//
// Table
// --------------------------------------------------------------------------
table {
  border-collapse: collapse;
  margin: $small-spacing 0;
  table-layout: fixed;
  width: 100%;
}

th {
  border-bottom: 1px solid shade($base-border-color, 25%);
  font-weight: 600;
  padding: $small-spacing 0;
  text-align: left;
}

td {
  border-bottom: $base-border;
  padding: $small-spacing 0;
}

tr,
td,
th {
  vertical-align: middle;
}


//
// Lists
// --------------------------------------------------------------------------
ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

dl {
  margin: 0;
}

dt {
  font-weight: 600;
  margin: 0;
}

dd {
  margin: 0;
}


//
// Media
// --------------------------------------------------------------------------
figure {
  margin: 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
  height: auto;
}
