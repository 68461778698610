// ==========================================================================
// BASE / TYPOGRAPHY
// ==========================================================================

body {
	color: $base-font-color;
	font-family: $base-font-family;
	font-size: $base-font-size;
	line-height: $base-line-height;
}

//
// Body Text
// --------------------------------------------------------------------------
p {
	margin: 0 0 $small-spacing;
}

p,
li {
	font-weight: 400;
	line-height: $base-line-height;
	margin-bottom: $base-line-height;
	font-size: $base-font-size * 0.9;

	strong {
		font-weight: 700;
	}

	@include respond-above("tablet-portrait") {
		font-size: $base-font-size;
	}
}

//
// Links
// --------------------------------------------------------------------------
a {
	color: $action-color;
	text-decoration: underline;
	transition: color $base-duration $base-timing;

	&:active,
	&:focus,
	&:hover {
		color: shade($action-color, 25%);
	}
}

//
// Headers
// --------------------------------------------------------------------------

// Generals
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $heading-font-family;
	font-size: modular-scale(1);
	line-height: $heading-line-height;
	margin: 0 0 $small-spacing;
	font-weight: 700;
	letter-spacing: -0.04em;
}

// Specifics
h1 {
	font-size: 2em;
	letter-spacing: -0.04em;
	margin-bottom: 0.25em;
	// color: $brand-blue;
}

h2 {
	font-size: 1.2em;
	font-family: $base-font-family;
}

h3 {
	padding-top: $small-spacing;
	font-size: 1.3em;
	// color: $brand-blue;
}

h4 {
	font-size: 1.15em;
}

h5 {
	font-size: 1em;
}

@include respond-above("tablet-portrait") {
	h1 {
		font-size: 2.15em;
	}
	h2 {
		font-size: 1.3em;
	}
	h3 {
		font-size: 1.4em;
	}
	h4 {
		font-size: 1.25em;
	}
	h5 {
		font-size: 1.15em;
	}
}

@include respond-above("tablet-landscape") {
	h1 {
		font-size: 2.5em;
	}
	h2 {
		font-size: 1.6em;
	}
	h3 {
		font-size: 1.6em;
	}
	h4 {
		font-size: 1.4em;
	}
	h5 {
		font-size: 1.3em;
	}
}

@include respond-above("desktop") {
	h1 {
		font-size: 3em;
	}
	h2 {
		font-size: 2em;
	}
	h3 {
		font-size: 1.8em;
	}
	h4 {
		font-size: 1.5em;
	}
	h5 {
		font-size: 1.4em;
	}
}

/* --------------------------------- HELPERS -------------------------------- */
.sm-caps {
	text-transform: lowercase;
	font-variant: small-caps;
}

.sr-only {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}

.screenreader-text {
	position: absolute;
	left: -999px;
	width: 1px;
	height: 1px;
	top: auto;

	&:focus {
		display: inline-block;
		height: auto;
		width: auto;
		position: static;
		margin: auto;
	}
}

.legal {
	font-size: 80%;
}

/* --------------------------- SPECIFIC TYPE -------------------------- */
.banner-header {
	h2 {
		font-size: 1.8rem;
		font-family: $heading-font-family;
		color: #ff2f59;
		line-height: 1;
		text-shadow: 0 2px 6px rgba(black, 0.1), 0 1px 2px rgba(black, 0.1);
		letter-spacing: -0.03em;
	}
	h3 {
		font-size: 1.3rem;
		color: white;
		margin-bottom: 0.35em;
		text-shadow: 0 2px 6px rgba(black, 0.1), 0 1px 2px rgba(black, 0.1);
		letter-spacing: 0.03em;
		font-weight: 700;
		font-family: $base-font-family;
	}

	@include respond-above("tablet-portrait") {
		h2 {
			font-size: 4vw;
		}
		h3 {
			font-size: 3vw;
		}
	}
	@include respond-above("tablet-landscape") {
		h2 {
			font-size: 3.2rem;
		}
		h3 {
			font-size: 1.5rem;
		}
	}
	@include respond-above("desktop") {
		h2 {
			font-size: 3.6rem;
		}
		h3 {
			font-size: 1.7rem;
		}
	}
}
.banner-content {
	padding: $base-spacing;
	p {
		font-size: 1rem;
		font-weight: 400;
		color: #d0eeff;
		margin-bottom: 1rem;
		text-shadow: 0 2px 6px rgba(black, 0.1), 0 1px 2px rgba(black, 0.1);

		strong {
			font-weight: 800;
			font-style: italic;
			color: white;
			letter-spacing: 0.03em;
		}
	}

	@include respond-above("tablet-portrait") {
		padding: 0;
		margin-bottom: $base-spacing;

		p {
			font-size: 0.9rem;
		}
	}
	@include respond-above("tablet-landscape") {
		p {
			font-size: 1.1rem;
		}
	}
}

.form-wrapper {
	header {
		h2 {
			font-size: 1rem;
			//font-size: clamp(16px, 1.2vw, 22px);
			color: #5da7c5;
			text-transform: uppercase;
			line-height: 1.2;
			letter-spacing: 0.1em;
			margin-bottom: 0 !important;

			strong {
				font-size: 170%;
				font-family: $serif;
				text-transform: none;
				display: block;
				color: #282f50;
				letter-spacing: -0.05em;
			}

			// @include mq(small) {
			// 	font-size: 0.9rem;
			// }
			// @include mq(medium) {
			// 	font-size: 1.2rem;
			// }
		}
	}
}

#feature-content {
	h2 {
		text-align: center;
		font-family: $serif;
		margin-bottom: $base-spacing;
		color: $ffg-red;
		font-size: 1.8rem;
	}
	h3 {
		font-family: $sans-serif;
		margin-bottom: $small-spacing / 2;
		color: $ffg-aqua;
		padding-top: 0;
	}
	.feature {
		text-align: left;

		@include respond-above("tablet-portrait") {
			text-align: center;
		}
	}

	@include respond-above("tablet-landscape") {
		h2 {
			font-size: 2.1rem;
		}
	}
}

/* --------------------------------- FOOTER --------------------------------- */

#site-footer {
	text-align: center;
	color: tint($ffg-red, 30);

	a {
		color: tint($ffg-red, 20);

		&:hover {
			color: tint($ffg-red, 60);
		}
	}
}
